import { Controller } from "@hotwired/stimulus"
import { addEventListener } from "@oddcamp/js-utils/src/event"
import { removeClass, addClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../utils/stimulus"

class DocumentsList extends Controller {
  static values = {
    activeType: String,
  }

  static targets = [`tabButton`, `tabContainer`, `content`]

  #clicked = false

  connect() {
    if (!this.hasTabContainerTarget) return

    addEventListener(this.tabButtonTargets, `click`, this.tabButtonClick)
    window.addEventListener(`keyup`, this.windowKeyup, { passive: true })

    // Get URL hash
    const hash = window.location.hash.replace(`#`, ``)

    // If hash is set, change the active tab
    if (hash) this.activeTypeValue = hash
  }

  disconnect() {
    if (!this.hasTabContainerTarget) return

    window.removeEventListener(`keyup`, this.windowKeyup, { passive: true })
  }

  activeTypeValueChanged() {
    if (!this.hasTabContainerTarget) return

    removeClass(this.tabContainerTargets, `--active`)
    const selectedTab = this.tabContainerTargets.find(
      (t) => t.dataset.type == this.activeTypeValue
    )

    if (!selectedTab) return

    selectedTab.classList.add(`--active`)

    this.tabButtonTargets.forEach((t) => {
      t.ariaSelected = false
      t.tabIndex = -1
    })

    const activeButton = this.tabButtonTargets.find(
      (t) => t.dataset.type == this.activeTypeValue
    )
    activeButton.ariaSelected = true
    activeButton.tabIndex = 0
    if (this.#clicked) activeButton.focus()
    this.#clicked = false

    removeClass(this.contentTargets, `--active`)
    addClass(
      this.activeTypeValue == `all`
        ? this.contentTargets
        : this.contentTargets.filter(
            (t) => t.dataset.type == this.activeTypeValue
          ),
      `--active`
    )
  }

  tabButtonClick = (e, targetEl) => {
    e.preventDefault()
    this.#clicked = true
    this.activeTypeValue = targetEl.dataset.type

    // Update URL hash when tab is changed
    location.hash = `#${this.activeTypeValue}`
  }

  windowKeyup = (e) => {
    if (!this.hasTabContainerTarget) return

    let index = this.tabButtonTargets.indexOf(document.activeElement)

    if (
      [`ArrowLeft`, `ArrowRight`, `ArrowUp`, `ArrowDown`].includes(e.key) &&
      index > -1
    ) {
      this.#clicked = true

      if ([`ArrowLeft`, `ArrowUp`].includes(e.key)) {
        index--
        this.activeTypeValue = (
          index < 0
            ? this.tabButtonTargets[this.tabButtonTargets.length - 1]
            : this.tabButtonTargets[index]
        ).dataset.type
      } else {
        index++
        this.activeTypeValue = index >= this.tabButtonTargets.length ? 0 : index

        this.activeTypeValue = (
          index >= this.tabButtonTargets.length
            ? this.tabButtonTargets[0]
            : this.tabButtonTargets[index]
        ).dataset.type
      }
    }

    // Update URL hash when tab is changed
    location.hash = `#${this.activeTypeValue}`
  }
}

Stimulus.register(`documents-list`, DocumentsList)

// modal

class DocumentsListModal extends Controller {
  static targets = [`container`]
  #iframe = null

  connect() {
    this.element.__MODAL__.on(`show`, () => {
      if (this.#iframe) this.#iframe.remove()
      this.#iframe = document.createElement(`iframe`)
      this.#iframe.setAttribute(`src`, this.containerTarget.dataset.url)
      this.containerTarget.appendChild(this.#iframe)
    })
  }
}

Stimulus.register(`documents-list-modal`, DocumentsListModal)
