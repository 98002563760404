import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class FullModal extends Controller {
  static targets = [`content`]

  connect() {
    this.element.addEventListener(`fullModal:scrollToTop`, this.scrollToTop)
  }

  disconnect() {
    this.element.removeEventListener(`fullModal:scrollToTop`, this.scrollToTop)
  }

  scrollToTop = () => {
    this.contentTarget.scrollTop = 0
  }
}

Stimulus.register(`full-modal`, FullModal)
