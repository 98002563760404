import { Controller } from "@hotwired/stimulus"
import throttle from "raf-throttle"

import Stimulus from "../utils/stimulus"

class Flash extends Controller {
  static targets = [`inner`]
  #alignmentEl = document.querySelector(`.layout-users__main`)
  #winResizeHandler = null

  connect() {
    if (this.#alignmentEl) {
      this.#winResizeHandler = throttle(this.align)
      window.addEventListener(`resize`, this.#winResizeHandler)
      this.align()
    }
  }

  disconnect() {
    window.addEventListener(`resize`, this.#winResizeHandler)
  }

  hide() {
    this.element.remove()
  }

  align = () => {
    const { x, width } = this.#alignmentEl.getBoundingClientRect()
    const pos = x ? x / 2 : 0

    this.innerTarget.style.maxWidth = null
    const maxWidth = Math.min(
      parseFloat(
        window.getComputedStyle(this.innerTarget).getPropertyValue(`max-width`)
      ),
      width
    )
    this.innerTarget.style.maxWidth = `${maxWidth}px`
    this.innerTarget.style.transform = `translateX(${pos}px)`
  }
}

Stimulus.register(`flash`, Flash)
