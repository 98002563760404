import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class Button extends Controller {
  connect() {
    this.toggleDisableOnFormSubmit(true)

    this.listenToDisableChanges()

    document.documentElement.addEventListener(
      `turbo:before-visit`,
      this.beforeTurboVisit
    )
  }

  disconnect() {
    this.toggleDisableOnFormSubmit(false)

    document.documentElement.removeEventListener(
      `turbo:before-visit`,
      this.beforeTurboVisit
    )
  }

  toggleDisableOnFormSubmit = (on) => {
    if (this.shouldDisableOnFormSubmit()) {
      const formEl = this.element.closest(`form`)
      if (formEl) {
        formEl[on ? `addEventListener` : `removeEventListener`](
          `submit`,
          this.toggleLoadingDisabled
        )
      }
    }
  }

  toggleLoadingDisabled = (disable = true) => {
    this.element.disabled = disable
    this.element.classList[disable ? `add` : `remove`](`button--loading`)
  }

  beforeTurboVisit = () => {
    if (this.shouldDisableOnFormSubmit()) this.toggleLoadingDisabled(false)
  }

  shouldDisableOnFormSubmit = () => {
    return this.element.classList.contains(`button--disable-on-form-submit`)
  }

  listenToDisableChanges = () => {
    if (!window.MutationObserver || !this.element.type) return

    new MutationObserver(() => {
      this.element.classList.toggle(`button--disabled`, this.element.disabled)
    }).observe(this.element, {
      attributes: true,
    })
  }
}

Stimulus.register(`button`, Button)
