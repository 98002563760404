import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

import Stimulus from "../utils/stimulus"

class Infotip extends Controller {
  static values = { content: String }
  #tippy = null

  connect() {
    this.#tippy = tippy(this.element, {
      content: this.contentValue,
      allowHTML: true,
      interactive: true,
      interactiveBorder: 30,
    })
  }

  disconnect() {
    this.#tippy.destroy()
  }
}

Stimulus.register(`infotip`, Infotip)
