import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

import Stimulus from "../utils/stimulus"

class Notice extends Controller {
  static values = { noticeId: String }

  close() {
    Cookies.set(`notice`, this.noticeIdValue, { sameSite: `Lax` })
    this.element.remove()
  }
}

Stimulus.register(`notice`, Notice)
