import { Controller } from "@hotwired/stimulus"
import A11yDialog from "a11y-dialog"
import { hasClosest } from "@oddcamp/js-utils/src/selector"
import {
  addEventListener,
  delegateEventListener,
} from "@oddcamp/js-utils/src/event"

import Stimulus from "../utils/stimulus"

const getWinHash = () => window.location.hash.replace(`#`, ``)

class Modal extends Controller {
  static targets = [`content`, `close`]
  static values = { history: Boolean }

  #dialog

  connect() {
    this.#dialog = new A11yDialog(this.element)
    this.element.__MODAL__ = this.#dialog

    this.#dialog.on(`show`, () => {
      document.documentElement.classList.add(`--modal-open`)

      if (this.historyValue && getWinHash() != this.element.id)
        window.history.pushState(null, null, `#${this.element.id}`)
    })

    this.#dialog.on(`hide`, () => {
      document.documentElement.classList.remove(`--modal-open`)

      if (this.historyValue && getWinHash() == this.element.id)
        window.history.pushState(null, null, ` `)
    })

    this.setupShowTriggers()
    this.setupHideTriggers()

    if (this.historyValue) {
      window.addEventListener(`popstate`, this.winPopstateChange)
      if (getWinHash() == this.element.id && !this.#dialog.shown)
        this.#dialog.show()
    }
  }

  disconnect() {
    window.removeEventListener(`popstate`, this.winPopstateChange)
    this.#dialog.destroy()
    this.#dialog = null
  }

  setupShowTriggers = () => {
    delegateEventListener(
      `[data-modal-show="${this.element.id.replace(/^modal-/, ``)}"]`,
      `click`,
      (e) => {
        if (!this.#dialog) return
        e.preventDefault()
        this.element.__MODAL_TRIGGER__ = e.target.closest(`[data-modal-show]`)
        this.#dialog.show()
      }
    )
  }

  setupHideTriggers = () => {
    if (this.hasContentTarget) {
      this.element.addEventListener(`click`, (e) => {
        this.contentTargets.forEach((contentTarget) => {
          if (!hasClosest(e.target, contentTarget)) {
            e.preventDefault()
            this.#dialog.hide()
          }
        })
      })
    }

    if (this.hasCloseTarget) {
      addEventListener(this.closeTargets, `click`, (e) => {
        e.preventDefault()
        this.#dialog.hide()
      })
    }
  }

  winPopstateChange = () => {
    if (getWinHash() == this.element.id) {
      if (!this.#dialog.shown) this.#dialog.show()
    } else {
      if (this.#dialog.shown) this.#dialog.hide()
    }
  }
}

Stimulus.register(`modal`, Modal)
