import * as Turbo from "@hotwired/turbo"

// make it globally available

window.Turbo = Turbo

// scroll to the top of the form on submit end

document.addEventListener(`turbo:submit-end`, (e) => {
  e.detail.formSubmission.formElement.scrollIntoView(true)
})

// Turbo wouldn't scroll to the top on page visit on Firefox if
// `scroll-behavior: smooth;` is set on `html`:
// https://github.com/hotwired/turbo/issues/426
// this is the fix:

if (window.getComputedStyle(document.documentElement).scrollBehavior) {
  document.addEventListener(`turbo:load`, () => {
    document.documentElement.style.scrollBehavior = `smooth`
  })

  document.addEventListener(`turbo:before-visit`, () => {
    document.documentElement.style.scrollBehavior = `unset`
  })
}
