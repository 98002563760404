import { Controller } from "@hotwired/stimulus"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import Fuse from "fuse.js"

import Stimulus from "../utils/stimulus"

// search

class FaqPageSearch extends Controller {
  static targets = [`input`, `list`, `listItem`, `empty`, `contact`, `other`]
  #searchable = []

  connect() {
    if (this.hasListItemTarget) {
      this.listItemTargets.forEach((i) =>
        this.#searchable.push({
          title: i.dataset.searchTitle,
          body: i.dataset.searchBody.replace(/(<([^>]+)>)/gi, ``),
        })
      )
    }
  }

  search(e) {
    if (!this.hasListItemTarget) return

    const value = e.target.value.trim()

    if (value) {
      addClass(this.listItemTargets, `hidden`)
      addClass(this.emptyTarget, `hidden`)
      addClass(this.otherTargets, `hidden`)
      if (this.hasContactTarget) addClass(this.contactTarget, `hidden`)

      const fuse = new Fuse(this.#searchable, { keys: [`title`, `body`] })
      const results = fuse.search(value)

      results.forEach((result) =>
        this.listItemTargets[result.refIndex].classList.remove(`hidden`)
      )

      if (!results.length) {
        removeClass(this.emptyTarget, `hidden`)
        if (this.hasContactTarget) removeClass(this.contactTarget, `hidden`)
      }
    } else {
      if (this.hasContactTarget) removeClass(this.contactTargets, `hidden`)
      removeClass(this.otherTargets, `hidden`)
      addClass(this.emptyTarget, `hidden`)
      addClass(this.listItemTargets, `hidden`)
      removeClass(
        this.listItemTargets.slice(0, this.listTarget.dataset.limit),
        `hidden`
      )
    }
  }
}

Stimulus.register(`faq-page-search`, FaqPageSearch)

// feedback

class FaqPageFeedback extends Controller {
  static targets = [`contact`, `buttons`, `thanks`]

  useless() {
    if (this.hasContactTarget) this.contactTarget.classList.toggle(`--visible`)
  }

  helpful() {
    if (this.hasContactTarget) this.contactTarget.classList.remove(`--visible`)
    this.buttonsTarget.classList.add(`hidden`)
    this.thanksTarget.classList.remove(`hidden`)
  }
}

Stimulus.register(`faq-page-feedback`, FaqPageFeedback)

// topic answer: open all links in a new tab

class FaqPageTopicAnswer extends Controller {
  connect() {
    this.element.querySelectorAll(`a`).forEach((a) => {
      a.target = `_blank`
      a.rel = `${a.rel || ``} noopener noreferrer`.trim()
    })
  }
}

Stimulus.register(`faq-page-topic-answer`, FaqPageTopicAnswer)

// scroller (scroll to top when rendered in FullModal)

class FaqPageScroller extends Controller {
  connect() {
    const modalEl = this.element.closest(`.full-modal`)
    if (modalEl) {
      modalEl.dispatchEvent(new CustomEvent(`fullModal:scrollToTop`))
    }
  }
}

Stimulus.register(`faq-page-scroller`, FaqPageScroller)
