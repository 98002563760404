import { Controller } from "@hotwired/stimulus"
import { removeClass, addClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../utils/stimulus"

class TabbedContent extends Controller {
  static values = { activeIndex: Number }

  static targets = [`tabButton`, `tabContainer`, `content`]

  #clicked = false

  connect() {
    window.addEventListener(`keyup`, this.windowKeyup, { passive: true })

    const winHash = window.location.hash.replace(`#`, ``)
    if (winHash) {
      const contentTarget = this.contentTargets.find((t) => t.id == winHash)
      if (contentTarget) {
        window.setTimeout(() => {
          window.scrollTo(0, 0)
          window.setTimeout(() => this.element.scrollIntoView(true), 100)
        }, 10)
      }
    }
  }

  disconnect() {
    window.removeEventListener(`keyup`, this.windowKeyup, { passive: true })
  }

  activeIndexValueChanged() {
    removeClass(this.tabContainerTargets, `--active`)
    addClass(this.tabContainerTargets[this.activeIndexValue], `--active`)

    this.tabButtonTargets.forEach((t) => {
      t.ariaSelected = false
      t.tabIndex = -1
    })
    this.tabButtonTargets[this.activeIndexValue].ariaSelected = true
    this.tabButtonTargets[this.activeIndexValue].tabIndex = 0
    if (this.#clicked) this.tabButtonTargets[this.activeIndexValue].focus()

    const activeContentTarget = this.contentTargets[this.activeIndexValue]
    removeClass(this.contentTargets, `--active`)
    addClass(activeContentTarget, `--active`)

    if (this.#clicked && activeContentTarget.dataset.pushstate == `true`)
      window.history.pushState(null, null, `#${activeContentTarget.id}`)

    this.#clicked = false

    document.dispatchEvent(
      new CustomEvent(`tabbedContent:tabSwitched`, {
        detail: { element: this.element, activeIndex: this.activeIndexValue },
      })
    )
  }

  switchTab = (e) => {
    e.preventDefault()
    this.#clicked = true
    this.activeIndexValue = this.tabButtonTargets.indexOf(e.currentTarget)
  }

  windowKeyup = (e) => {
    if (
      [`ArrowLeft`, `ArrowRight`, `ArrowUp`, `ArrowDown`].includes(e.key) &&
      this.tabButtonTargets.indexOf(document.activeElement) > -1
    ) {
      this.#clicked = true
      let index = this.activeIndexValue
      if ([`ArrowLeft`, `ArrowUp`].includes(e.key)) {
        index--
        this.activeIndexValue =
          index < 0 ? this.tabButtonTargets.length - 1 : index
      } else {
        index++
        this.activeIndexValue =
          index >= this.tabButtonTargets.length ? 0 : index
      }
    }
  }
}

Stimulus.register(`tabbed-content`, TabbedContent)
