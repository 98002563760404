import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"
import { throttle, debounce } from "lodash"
import { triggerEvent } from "@oddcamp/js-utils/src/event"
import flatpickr from "flatpickr"
import { Swedish } from "flatpickr/dist/l10n/sv.js"
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js"

import Stimulus from "../utils/stimulus"
import domReady from "../utils/dom-ready"
import { ordDate } from "../utils/ordinal-day"
import { addClass } from "@oddcamp/js-utils/src/attribute"

class FormInput extends Controller {
  static values = {
    choices: Boolean,
    choicesMax: String,
    choicesMaxText: String,
    choicesNoResultsText: String,
    currency: String,
    accountNumber: Boolean,
    accountNumberErrors: Object,
    telephoneNumber: Boolean,
    datePicker: Boolean,
  }

  #tagName = this.element.tagName.toLowerCase()
  #accountNumberXhr = null
  #accountNumberMajorCheck = false

  connect() {
    if (this.#tagName == `select`) {
      this.initSelectInput()
    } else if ([`integer`, `float`].includes(this.currencyValue)) {
      this.initCurrencyInput()
    } else if (this.accountNumberValue) {
      this.initAccountNumberInput()
    } else if (this.telephoneNumberValue) {
      this.initTelephoneNumberInput()
    } else if (this.datePickerValue) {
      this.initDatePicker()
    }
  }

  disconnect() {
    if (this.#tagName == `select` && this.choicesValue) {
      document.removeEventListener(`turbo:before-cache`, this.destroyChoices)
    }
  }

  initSelectInput = () => {
    this.toggleSelectPlaceholderClass()

    this.element.addEventListener(`change`, this.toggleSelectPlaceholderClass, {
      passive: true,
    })

    this.element.addEventListener(`blur`, this.toggleSelectPlaceholderClass, {
      passive: true,
    })

    if (this.choicesValue) {
      if (!this.element.__CHOICES) {
        const options = {
          shouldSort: false,
          searchEnabled: false,
        }

        if (this.hasChoicesMaxValue) {
          options.maxItemCount = this.choicesMaxValue

          if (this.hasChoicesMaxTextValue)
            options.maxItemText = (max) =>
              this.choicesMaxTextValue.replace(`[max]`, max)
        }

        if (this.hasChoicesNoResultsTextValue)
          options.noResultsText = this.choicesNoResultsTextValue

        this.element.__CHOICES_OPTIONS = [...this.element.options].map((o) => ({
          value: o.value,
          label: o.text,
          ...o.dataset,
        }))
        this.element.__CHOICES_HTML = this.element.outerHTML
        this.element.__CHOICES = new Choices(this.element, options)

        this.element.addEventListener(`showDropdown`, () => {
          this.element.__CHOICES.input.element.setAttribute(`type`, `search`)
        })
      }

      document.addEventListener(`turbo:before-cache`, this.destroyChoices)
    }
  }

  toggleSelectPlaceholderClass = () => {
    this.element.classList.toggle(`--placeholder`, !this.element.value)
  }

  initCurrencyInput = () => {
    this.normalizeValueOnInput(
      this.currencyValue == `integer` ? /^\d*$/ : /^\d*[.|,]?\d{0,2}$/
    )

    const validKeyRegEx = new RegExp(/^[0-9,.]+$/)

    if (this.element.dataset.formInputWithdrawCurrency) {
      this.element.addEventListener(`input`, this.trackCurrencyInputValue, {
        passive: true,
      })
      this.element.addEventListener(`keypress`, (e) => {
        if (
          e.key === `-` ||
          e.key === `+` ||
          e.key === `´` ||
          e.key === `\`` ||
          e.key === `¨` ||
          e.key === `^` ||
          e.key === `E` ||
          e.key === `e` ||
          !validKeyRegEx.test(e.key)
        ) {
          e.preventDefault()
        }

        if (e.key === `,` || e.key === `.`) {
          if (
            e.target.value.indexOf(`,`) !== -1 ||
            e.target.value.indexOf(`.`) !== -1
          ) {
            e.preventDefault()
          }
        }
      })
    }
  }

  trackCurrencyInputValue = throttle(
    (e) => {
      const maxVal = Number(this.element.dataset.max)

      if (!this.element.value) {
        triggerEvent(this.element, `setNote`, { message: null })
        triggerEvent(this.element, `setValid`, { valid: null })
        triggerEvent(this.element, `setError`, { message: null })
        return
      }

      if (isNaN(Number(this.element.value))) return

      const val = Number(this.element.value)
      const btnSubmitEl = document.querySelector(
        `#new_payment_order_form button[type="submit"]`
      )

      const setSubmitBtnDisabled = (val) => {
        if (btnSubmitEl) {
          btnSubmitEl.disabled = val
        }
      }

      if (val < 0.01) {
        triggerEvent(this.element, `setError`, {
          message: `Du måste göra ett uttag på minst 0.01 SEK.`,
        })
        setSubmitBtnDisabled(true)

        return
      }

      if (val > 1050000) {
        triggerEvent(this.element, `setError`, {
          message: `Uttag upp till 1 050 000 SEK är möjligt här. Önskar du genomföra större uttag kontakta Borgos kundsupport på nummer 010 - 52 52 500.`,
        })
        setSubmitBtnDisabled(true)

        return
      }

      if (maxVal && val > maxVal) {
        triggerEvent(this.element, `setError`, {
          message: `Beloppet är större än saldot på ditt konto.`,
        })
        setSubmitBtnDisabled(true)

        return
      }

      triggerEvent(this.element, `setError`, { message: null })
      setSubmitBtnDisabled(false)
    },
    500,
    { leading: false }
  )

  destroyChoices = () => {
    this.element.__CHOICES.destroy()
    this.element.__CHOICES = null
    this.element.outerHTML = this.element.__CHOICES_HTML
  }

  initAccountNumberInput = () => {
    this.element.addEventListener(`input`, this.trackAccountNumberValue, {
      passive: true,
    })

    //
    ;[`blur`, `drop`, `paste`].forEach((event) => {
      this.element.addEventListener(event, this.initAccountNumberValueCheck, {
        passive: true,
      })
    })

    domReady(() => {
      this.#accountNumberMajorCheck = true
      this.trackAccountNumberValue()
    })
  }

  trackAccountNumberValue = throttle(
    () => {
      if (!this.element.value) {
        this.#accountNumberMajorCheck = false
        triggerEvent(this.element, `setNote`, { message: null })
        triggerEvent(this.element, `setValid`, { valid: null })
        triggerEvent(this.element, `setError`, { message: null })
        return
      }

      window.Rails.ajax({
        url: window.BORGO.usersApiAutogiroLookupPath,
        data: new URLSearchParams({
          account_number: this.element.value,
        }).toString(),
        type: `get`,

        beforeSend: (xhr) => {
          if (this.#accountNumberXhr) this.#accountNumberXhr.abort()
          this.#accountNumberXhr = xhr
          return true
        },

        complete: () => {
          this.#accountNumberXhr = null
        },

        success: (response) => {
          triggerEvent(this.element, `setNote`, { message: response.bank })
          triggerEvent(this.element, `setValid`, { valid: response.valid })

          if (response.valid)
            triggerEvent(this.element, `setError`, { message: null })

          if (this.#accountNumberMajorCheck) {
            this.#accountNumberMajorCheck = false

            if (response.valid) this.element.value = response.normalized

            if (response.errors.length)
              triggerEvent(this.element, `setError`, {
                message: response.errors.join(`; `),
              })
          }
        },
      })
    },
    500,
    { leading: false }
  )

  initAccountNumberValueCheck = debounce(() => {
    this.#accountNumberMajorCheck = true
    this.trackAccountNumberValue.cancel()
    this.trackAccountNumberValue()
  }, 500)

  initTelephoneNumberInput = () => {
    this.normalizeValueOnInput(/^[+]?\d*$/)
  }

  initDatePicker = () => {
    const current = new Date()

    domReady(() => {
      const options = {
        defaultDate: current,
        dateFormat: `Y-m-d`,
        enableTime: false,
        monthSelectorType: `static`,
        animate: false,
        minDate: `today`,
        onChange: this.updateDateValue,
        onOpen: this.addOverlay,
        onClose: this.removeOverlay,
        onMonthChange: (selectedDates, dateStr, instance) => {
          this.styleWeekEnd(instance.days.querySelectorAll(`.flatpickr-day`))
        },
        closeOnSelect: false,
        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: ``,
            confirmText: `Välj`,
            showAlways: true,
          }),
        ],
      }

      flatpickr.localize(Swedish)
      flatpickr.l10ns.default.firstDayOfWeek = 1
      const flatp = flatpickr(this.element, options)
      this.styleWeekEnd(flatp.days.querySelectorAll(`.flatpickr-day`))
      this.updateDateValue(current)
    })
  }

  addOverlay() {
    document.documentElement.classList.add(`--modal-open`)
  }

  removeOverlay() {
    document.documentElement.classList.remove(`--modal-open`)
  }

  updateDateValue(selectDate) {
    const recurringEl = document.querySelector(`#recurring-date-desc input`)
    const curDate = (
      Array.isArray(selectDate) ? selectDate[0] : selectDate
    ).getDate()
    const ordinalDate = ordDate(curDate)
    recurringEl.value = `${ordinalDate} varje månad`
  }

  styleWeekEnd(days) {
    days.forEach((day) => {
      if (day.dateObj.getDay() === 0 || day.dateObj.getDay() === 6) {
        addClass(day, `weekend`)
      }
    })
  }

  normalizeValueOnInput = (regex) => {
    this.element.addEventListener(
      `input`,
      () => {
        if (regex.test(this.element.value)) {
          this.element.__value = this.element.value
          this.element.__selectionStart = this.element.selectionStart
          this.element.__selectionEnd = this.element.selectionEnd
        } else if (this.element.__value) {
          this.element.value = this.element.__value
          if (
            this.element.__selectionStart !== null &&
            this.element.__selectionEnd !== null
          ) {
            this.element.setSelectionRange(
              this.element.__selectionStart,
              this.element.__selectionEnd
            )
          }
        } else this.element.value = ``
      },
      { passive: true }
    )
  }
}

Stimulus.register(`form-input`, FormInput)
