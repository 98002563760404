const ordDate = (day) => {
  let suffix = `e`

  if (day > 3 && day < 21) return day + suffix

  switch (day % 10) {
    case 1:
      suffix = `a`
      break
    case 2:
      suffix = `a`
      break
    case 3:
      suffix = `e`
      break
  }

  return day + suffix
}

export { ordDate }
