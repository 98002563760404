import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class DocumentModal extends Controller {
  static targets = [`container`]
  #iframe = null

  connect() {
    this.element.__MODAL__.on(`show`, () => {
      if (this.#iframe) this.#iframe.remove()
      this.#iframe = document.createElement(`iframe`)
      this.#iframe.setAttribute(`src`, this.containerTarget.dataset.url)
      this.containerTarget.appendChild(this.#iframe)
    })
  }
}

Stimulus.register(`document-modal`, DocumentModal)
